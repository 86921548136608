.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
}

.logo {
    height: auto;
    max-width: 30%;
    margin-bottom: 0;
}

.button-container {
    display: flex;
    justify-content: center;
}

.team-button {
    margin: auto;
}

.dialog-title {
    color: #FC7A1E;
    text-align: center;
    background-color: #E8E4D6;
    font-size: 2rem !important;
}

.info-dialog-content {
    font-family: 'Montserrat', sans-serif;
    padding: 20px;
    background-color: #E8E4D6;
    border-radius: 5px;
}

/* Responsive design for mobile devices */
@media (max-width: 768px) {
    .new-header {
        flex-direction: column;
        text-align: center;
        padding-bottom: 0;
    }

    .logo {
        margin-bottom: 5px;
        max-width: 80%;
    }

    .button-container {
        width: 100%;
    }
}
