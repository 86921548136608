html, body {
    height: 100vh;
    margin: 0;
    display: flex;
    flex-direction: column;
}

#root {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 0; /* Prevent flex items from overflowing */
}

.footer {
    background-color: #333;
    color: #E8E4D6;
    width: 100%;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    font-family: 'Montserrat', sans-serif;
    margin: auto;
}

.footer-info {
    flex-grow: 1;
}
.footer-legal {
    flex-grow: 1;
    font-size: 0.4rem;
}

@media (max-width: 768px) {
    .footer {
        font-size: 0.8rem;
        align-items: center;
        flex-direction: column;
        flex-wrap: wrap;
    }

    .footer > * {
        flex: 1 1 100%;
    }
}
