.home {
    width: 50%;
    margin: 0 auto;
}

.slick-slider .slick-list,
.slick-slider .slick-track {
    display: flex;
    align-items: center;
    justify-content: center;
}

.slick-slide > div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.slick-prev,
.slick-next {
    font-size: 24px;
    line-height: 1;
    opacity: 1;
    background-color: #E8E4D6;
    color: #FC7A1E;
    border: none;
    border-radius: 50%;
    padding: 10px;
    z-index: 1000;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.2s ease-in-out;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.slick-prev:hover,
.slick-next:hover,
.slick-prev:focus,
.slick-next:focus,
.slick-prev:active,
.slick-next:active {
    opacity: 100;
    color: #FC7A1E;
    outline: none;
    box-shadow: none;
}

.slick-prev {
    background-color: transparent;
    left: 50px;
}

.slick-next {
    background-color: transparent;
    right: 50px;
}

.slick-prev:before,
.slick-next:before {
    content: '';
}

.additional-h1 {
    margin-top: 6rem;
    color: #FC7A1E;
    font-family: 'Bebas Neue', sans-serif;
    font-size: 1rem;
}

.carousel-item {
    color: #333333;
    padding: 2rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    font-family: 'Montserrat', sans-serif;
    min-height: 300px;
}

.carousel-item h2 {
    color: #FC7A1E;
    font-family: 'Bebas Neue', sans-serif;
    font-size: 2rem !important;
}

.carousel-item p {
    font-size: 1rem;
    line-height: 1.5;
    padding: 0 4rem;
}

.home {
    padding: 2rem 0;
}


.slick-dots {
    bottom: 2.5vh;
    font-size: 12px;
}

.slick-dots li button:before {
    font-size: 12px;
}

.responsive-image {
    max-width: 40%;
    height: auto;
    display: block;
    margin: 0 auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    transform: scale(1.0);
    transition: opacity 0.7s ease, transform 0.7s ease-out;
    opacity: 0.6;
}

.responsive-image-2 {
    max-width: 40%;
    height: auto;
    display: block;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    opacity: 1;
    margin: 2rem auto 2rem auto;
}

.responsive-image.in-view {
    opacity: 1;
    transform: scale(1);
}

.additional-text {
    padding: 30px;
    color: #333333;
    font-family: 'Montserrat', sans-serif !important;
    margin-bottom: -3rem;
    margin-top: -2rem;
}

.additional-h1 {
    margin-top: 1rem;
    color: #FC7A1E;
    font-family: 'Bebas Neue', sans-serif;
    font-size: 2rem;
}

.divider {
    height: 2px;
    width: 100%;
    background-color: #ccc;
    margin: 20px 0;
}


@media (max-width: 768px) {
    .carousel-item {
        min-height: 450px;
    }

    .carousel-item h2 {
        font-size: 1.5rem;
        color: #FC7A1E;
    }

    .carousel-item p {
        font-size: 1rem;
        line-height: 1.5;
        padding: 0 4rem;
    }

    .slick-prev,
    .slick-next {
        width: 30px;
        height: 30px;
        padding: 5px;
    }

    .slick-prev {
        left: 10px;
    }

    .slick-next {
        right: 10px;
    }

    .slick-dots li button:before {
        font-size: 12px;
        margin-top: 7rem;
    }

    @media (max-width: 768px) {
        .slick-dots {
            bottom: 15vh; /* Adjusted for smaller screens */
        }
    }

    .home {
        width: 100%;
        margin: 0 auto;
    }

    .responsive-image {
        opacity: 0.6;
        transform: scale(0.95);
        transition: opacity 0.5s ease, transform 0.5s ease-out;
    }

    .responsive-image.in-view {
        opacity: 1;
        transform: scale(1);
    }
}
