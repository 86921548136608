@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

body {
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: #E8E4D6;
    color: #333;
}

.App {
    text-align: center;
}
